<template>
  <div v-if="!reportError">
    <div>
      <div class="d-flex justify-content-between mb-2">
        <span>
          {{ $auth.user ? $auth.name : '' }}
        </span>
        <span>
          {{ date }}
        </span>
      </div>

      <report-print-brand-header report-type="Product" />

      <b-card class="mb-0">
        <template v-if="appBrand === 'srp'">
          Individual product data report showing the latest calculated data and evolution for the structured product shown below.
          An up-to-date version of this report is available on the StructrPro service at
          <b-link :href="fullReportLink.href">
            {{ fullReportLink.text }}
          </b-link>
        </template>
        <template v-else-if="appBrand === 'se'">
          Individual product data report showing the latest calculated data and evolution for the structured product shown below.
          An up-to-date version of this report is available on the Structured Edge Plus service at
          <b-link :href="fullReportLink.href">
            {{ fullReportLink.text }}
          </b-link>
        </template>
      </b-card>

      <b-card
        class="mb-1"
        text-variant="warning"
        align="center"
      >
        <h2 class="text-center">
          <span class="align-self-center">{{ report.PRODUCTNAME }}</span>
        </h2>
        <b-card-text
          v-if="report.basicinfo.titlemessage"
          class="mt-2"
        >
          {{ report.basicinfo.titlemessage }}
        </b-card-text>
      </b-card>

      <b-row class="match-height">
        <b-col
          cols="4"
          class="table-border"
        >
          <b-card no-body>
            <template #header>
              <b-card-title>Product Terms</b-card-title>
            </template>

            <b-card-sub-title class="card-body-padding">
              Basic terms and underlyings
            </b-card-sub-title>

            <b-table
              responsive
              :thead-class="'d-none'"
              :fields="defaultFields"
              :items="report.PRODUCTTERMS"
            />
          </b-card>
        </b-col>

        <b-col
          cols="4"
          class="table-border"
        >
          <report-table-card
            title="Product Information"
            explanation="Dates, status and links"
            :items="report.PRODUCTINFORMATION"
          />
        </b-col>

        <b-col cols="4">
          <report-table-card
            title="Product Features"
            explanation="Dynamic product feature list"
            :items="report.PRODUCTFEATURES"
            :loading="loading"
          />
        </b-col>

      </b-row>

      <b-row>
        <b-col
          lg="12"
        >
          <b-card no-body>
            <template #header>
              <b-card-title>{{ 'Underlying Asset'+(report.statedata_underlyingtable.content.length>1?'s':'') }} </b-card-title>
            </template>
            <template>
              <b-table
                class="print-table-container"
                responsive
                :sticky-header="true"
                :fields="report.statedata_underlyingtable.header"
                :items="report.statedata_underlyingtable.content"
              />
            </template>
          </b-card>

          <report-table-card
            v-if="reportInfo.TERMINATION.ISTERMINATED"
            title="Product Snapshot"
            explanation="Key product features and their current status"
            table-header
            :fields="report.statedata_covertable.header"
            :items="report.statedata_covertable.content"
            :loading="false"
          />
        </b-col>
      </b-row>

      <div class="pagebreak" />

      <b-row>
        <b-col lg="12">
          <report-table-card
            v-if="!reportInfo.TERMINATION.ISTERMINATED"
            title="Product Snapshot"
            explanation="Key product features and their current status"
            table-header
            :fields="report.statedata_covertable.header"
            :items="report.statedata_covertable.content"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-if="!reportInfo.TERMINATION.ISTERMINATED"
          cols="6"
        >
          <b-card
            no-body
            class="mb-0"
          >
            <template #header>
              <b-card-title> Payoff Distribution
              </b-card-title>
            </template>
            <b-card-body>
              <b-card-sub-title class="pb-2">
                Current distribution of outcomes at maturity
              </b-card-sub-title>
              <vue-apex-charts
                type="bar"
                height="250"
                :options="returnChartAbsOptions"
                :series="report.RETURNCHARTABS.SHOWDATA"
              />
            </b-card-body>
          </b-card>
        </b-col>

        <b-col
          v-if="!reportInfo.TERMINATION.ISTERMINATED"
          cols="6"
        >
          <b-card
            no-body
            class="mb-0"
          >
            <template #header>
              <b-card-title style="color: rgba(255,255,255,0);">
                .
              </b-card-title>
            </template>
            <b-card-body>
              <b-card-sub-title class="pb-2">
                Current distribution of returns at maturity
              </b-card-sub-title>
              <vue-apex-charts
                type="bar"
                height="250"
                :options="returnChartAnnOptions"
                :series="report.RETURNCHARTANN.SHOWDATA"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <events-chart
      :key="2"
      :product-id="productId"
      :for-print="true"
      @events-data-loaded="eventChartLoaded = true"
    />

    <disclaimer />

    <span
      v-if="printReady"
      class="pdf--print--ready"
    />
  </div>
  <error-display
    v-else
    @action-clicked="loadProductReport"
  />
</template>

<script>
import ProductReportMixin from '@/mixins/ProductReportMixin.vue'

export default {
  mixins: [ProductReportMixin],
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';

.dark-layout .product-snapshot-table th {
  color: #d0d2d6 !important;
}
</style>

<style lang="scss" scoped>
.h-160 {
  max-height: 160px;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 14px;
}
.card-min-height {
  min-height: 460px;
}

.dark-layout {
  background-color: #1e1e1e !important;
}

.table-border {
  border-right: 1px solid #ebe9f1;
}
</style>
